import React, { useEffect, useState, useContext } from "react";
import { MarketplaceContext } from "helpers/marketplaceContext";
import { CollectionList, CollectionListProps } from "./CollectionList";

function NFTTokenIds() {
  const { marketplaceDetails } = useContext(MarketplaceContext);

  const [NFTDetails, setNFTDetails] = useState<Array<any>>();

  useEffect(() => {
    if (!NFTDetails) {
      const details = marketplaceDetails.marketplaceNFTCollections.data.filter(
        (marketplaceNFT: any) => {
          return marketplaceNFT.NFTCollection.publicMint;
        }
      );

      setNFTDetails(details);
    }
  }, [marketplaceDetails.marketplaceNFTCollections.data, NFTDetails]);

  console.log(`NFTDetails22 ${JSON.stringify(NFTDetails)}`);

  if (!NFTDetails) {
    return <div>Loading...</div>;
  }

  const listProps: CollectionListProps = {
    collections: NFTDetails.map(x => x.NFTCollection),
    viewPath: "NFTDetails"
  }

  return (
    <>
    <CollectionList {...listProps}></CollectionList>
      
    </>
  );
}

export default NFTTokenIds;
