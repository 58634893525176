import { TransactionReceipt } from '@ethersproject/abstract-provider';
// import { TransactionReceipt } from 'ethereum-types';
import { useMoralis, useWeb3ExecuteFunction, Web3ExecuteFunctionParameters } from "react-moralis";
import 'antd/dist/antd.css';
import { notification } from 'antd';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import Moralis from 'moralis-v1';
import { ResolveCallOptions } from "react-moralis/lib/hooks/internal/_useResolveAsyncCall";
const useWeb3Utils = () => {

    const { Moralis } = useMoralis();
    const { fetch } = useWeb3ExecuteFunction();
    
    //useWeb3ExecuteFunction();

    // console.log(" loading useWeb3Utils")

    const openSuccessNotification = (message:string) => {
      notification.success({
        message: 'Success!',
        description:
          message,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    };

    const openErrorNotification = (message:string) => {
      notification.error({
        message: 'TransactionError',
        description:
          message,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    };

    //UseWeb3ExecuteFunctionOptions
    const readWeb3State = (params: ResolveCallOptions<unknown, Web3ExecuteFunctionParameters> ) => {

      return fetch(params)
    }

    const executeWeb3CallAndConfirm = async(options: Moralis.ExecuteFunctionOptions): Promise<TransactionReceipt | undefined> => {
      let result: TransactionReceipt | undefined;
      try{
        const transactionResult =  await Moralis.Web3.executeFunction(options) as unknown as TransactionResponse;
    
          console.log("waiting for 5 confirmations")
          try {

            result = await transactionResult.wait(5)

          } catch (waitError) {
            console.error(`wait error: ${waitError}`)

          }
          console.log("maybe works?")
          openSuccessNotification("Transaction confirmed")
          // return Promise.allSettled([transactionResult]);

      } catch (error: any ) {
        openErrorNotification(error.data?.message || error.message)
        console.warn(`there was an error while executeFunction ${JSON.stringify(error)}`)
      }
      return result;
        
          // execute()
    
      }

      return {executeWeb3CallAndConfirm, readWeb3State}

}

  export {useWeb3Utils}
