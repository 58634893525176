import React, { useState, useEffect } from 'react';
// import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useMarketplaceData } from "../hooks/useMarketplaceData"
import {  Spin } from "antd";
import { useChain, useMoralis } from 'react-moralis';
import { Marketplace } from 'types';


type MarketplaceContextProps = {
  marketplaceDetails: Marketplace;

}

export const MarketplaceContext = React.createContext<MarketplaceContextProps>(null as any);


const ROOT_API = process.env.REACT_APP_ROOT_BLOCKONE_API || "https://api.blockonelabs.com/api"
type MarketplaceProviderProps = {
  children: React.ReactNode;
}
export const MarketplaceProvider = (props: MarketplaceProviderProps) => {

  const [loading, setLoading] = useState(true)
  const [marketplaceDetails, setMarketplaceDetails] = useState<Marketplace>();
  // const [reference, setReference] = useState()
  // const [callback, setCallback] = useState()
  const { account } = useChain();
  const { user, logout } = useMoralis();

  useEffect(() => {
    

    console.log(`account ${account} vs ${user?.get("ethAddress")}`)

    if(user && account && user?.get("ethAddress") && account !== user.get("ethAddress")) {
      console.warn(`logged in with different metamask address logging out\n
      account: ${account} \n
      user.ethAddress: ${user?.get("ethAddress")}
      `)
      logout()
      return;
    }
  }, [account, user, logout])


  const [stylePath, setStylePath] = useState<string>(
    ""
  );

  const {marketplaceData} = useMarketplaceData()

  useEffect(() => {
    if(stylePath){

      var head = document.head;
      var link = document.createElement("link");
  
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = stylePath;
  
  
      head.appendChild(link);
  
      // return () => { head.removeChild(link); }
    }


  }, [stylePath]);



  //   console.log(" loading " + loading)
  useEffect(() => {
    // console.log("retrieving mktplace data")

  const retrieveMarketplaceData = async () => {


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params && Object.keys(params).length > 0){
      localStorage.setItem("queryParams", JSON.stringify(params));
    }
    const webhookParams = localStorage.getItem("queryParams") || "{}";


    // const referenceParam = params
    // if(referenceParam){
    //   setReference(referenceParam)
    // }
    console.log(`user `)
   
  
    const marketplaceId = marketplaceData?._id


    // console.log(`
    // user: ${JSON.stringify(user)} \n
    // account: ${account} \n
    // (user || {}).ethAddress: ${ethAddress}
    // `)
    if (account && user?.get("ethAddress")){
     
      const url = `${ROOT_API}/webhook/marketplace`
      fetch(url,
{
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({
      queryParams: webhookParams, 
      marketplaceId,
      user: user})
})
.then(function(res){ console.log(` callback ${JSON.stringify(res)}`) })
.catch(function(res){ console.log(`calback ${JSON.stringify(res)}`) })


    }    

    // const marketplaceId = window.location.pathname.split('/')[1];

    // console.log("marketplaceId " + marketplaceId)

    


    const marketPlace: Marketplace = (marketplaceData!)
    // const res = marketPlace.marketplaceNFTCollections
    const customStyleSheetURL = marketPlace.customStyleSheetURL
    setStylePath(customStyleSheetURL || "")

    // const webhookURL = marketPlace.webhookURL
    // console.log("webhookURL2 " +webhookURL)
    // if(webhookURL){
    //   setCallback(webhookURL)
    // }

    // console.log("res.data " + JSON.stringify(res.data))

    // const details = res.data.map((x:any) => x.NFTCollection.contractAddress)
    // console.log("details " + JSON.stringify(details))

    // console.log("asd " + JSON.stringify(asd))
    setMarketplaceDetails({
      ...marketPlace
    })
    setLoading(false)

  }

  if(marketplaceData) {
    retrieveMarketplaceData();
  }
    
  }, [account, marketplaceData, user, logout])


  if (loading) {
    return (
     
      <div className="flex justify-center mt-20">
    <Spin size="large"  spinning={true}/>

    </div>

    )
  }
  // console.log("stylePath " + stylePath)

  if(!marketplaceDetails) {
    return <div></div>
  }
 

  const providerValue: MarketplaceContextProps = {
    marketplaceDetails,
  }
  return (
    <div>

    <MarketplaceContext.Provider
    value={providerValue}
      // value={{ marketplaceDetails, reference }}
      
      >
      {props.children}
    </MarketplaceContext.Provider>
    </div>

  );
};