import { ResolveCallOptions } from "react-moralis/lib/hooks/internal/_useResolveAsyncCall";
import { useChain, Web3ExecuteFunctionParameters } from "react-moralis";
import { useState } from "react";
import { useWeb3Utils } from "./useWeb3Utils";
import VaultContract from "../contracts/Vault.json";
import ERC721Abi from "../contracts/ERC721.json";
import { MAX_APPROVE_AMOUNT } from "../helpers/constants";
import Moralis from 'moralis-v1';

import ERC20Schema from "../contracts/ERC20.json";

const useNFTUtils = () => {
  const { readWeb3State, executeWeb3CallAndConfirm } = useWeb3Utils();
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>();
  const [ownedNFTsIds, setOwnedNFTsIds] = useState<string[]>();
  const [NFTOwnershipLoaded, setNFTOwnershipLoaded] = useState<boolean>(false);
  const [NFTOwnershipLoading, setNFTOwnershipLoading] = useState<boolean>(false)
  const { account } = useChain();
  const [isMinting, setIsMinting] = useState(false);

  const [isApproving, setIsApproving] = useState(false);

  const setApprovalForAll = async (
    nftAddress: string,
    operatorAddress: string
  ) => {
    const options: Moralis.ExecuteFunctionOptions = {
      functionName: "setApprovalForAll",
      contractAddress: nftAddress,
      abi: VaultContract.abi,
      params: {
        approved: true,
        operator: operatorAddress,
      },
    };
    const res = await executeWeb3CallAndConfirm(options);
    console.log(`setApprovalForAll ${JSON.stringify(res)}`);
  };

  const depositFirstOwnedNFT = async (
    vaultAddress: string,
    depositNFTAddress: string,
  ) => {
    // nftAddress:string, ownerAddress:string, operatorAddress:string
    await checkIsApprovedForAll(depositNFTAddress, vaultAddress);

    // const depositNFT = async (nftId: string) => {
     
    // }

    const nftIds = await listNFTs(depositNFTAddress);
    console.log(`nftIds ${JSON.stringify(nftIds)}`);

    console.log(`depositNFT `)
    setIsApproving(false);
    setIsMinting(true);
    console.log(`ownedNFTsIds[0] ${nftIds![0]}`);
    const options: Moralis.ExecuteFunctionOptions = {
      functionName: "deposit",
      contractAddress: vaultAddress,
      abi: VaultContract.abi,
      params: {
        tokenId: nftIds![0],
      },
    };
    await executeWeb3CallAndConfirm(options);
    setIsMinting(false);

     
  };

  const listNFTs = async (nftAddress: string) => {
    setNFTOwnershipLoading(true)
    const nftIds: string[] = []
    await readWeb3State({
      params: {
        functionName: "balanceOf",
        contractAddress: nftAddress,
        abi: VaultContract.abi,
        params: {
          owner: account!,
        },
      },
      onSuccess: async (result: any) => {
        var q = Number(BigInt(result).toString());
        const nftPromises = [];
        for (var i = 0; i < q; i++) {
          const options: ResolveCallOptions<
            unknown,
            Web3ExecuteFunctionParameters
          > = {
            params: {
              functionName: "tokenOfOwnerByIndex",
              contractAddress: nftAddress,
              abi: VaultContract.abi,
              params: {
                owner: account!,
                index: i,
              },
            },
            // eslint-disable-next-line no-loop-func
            onSuccess: (tokenOfOwnerByIndexResult: any ) => {
              const tokenOfOwnerByIndexInt = BigInt(tokenOfOwnerByIndexResult);
              console.log(`tokenOfOwnerByIndex ${tokenOfOwnerByIndexInt}`);
              nftIds.push(tokenOfOwnerByIndexInt.toString());
            },
          };
          const promise = readWeb3State(options);
          nftPromises.push(promise);
        }
        await Promise.all(nftPromises)
        setNFTOwnershipLoaded(true);
        setNFTOwnershipLoading(false)
      },
    });

    // look for a better way to do this
    function wait(milliseconds: number) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    await wait(2000);


    setOwnedNFTsIds(nftIds);
    return nftIds;

  };
  const checkIsApprovedForAll = async (
    nftAddress: string,
    operatorAddress: string
  ) => {
    setIsApproving(true);
    console.log(
      `isApprovedForAll: ${nftAddress}, ${account}, ${operatorAddress}`
    );
    const isApproved = readWeb3State({
      params: {
        functionName: "isApprovedForAll",
        contractAddress: nftAddress,
        abi: VaultContract.abi,
        params: {
          owner: account,
          operator: operatorAddress,
        },
      },
      onSuccess: (result: any) => {
        const isApproved: boolean = String(result) === "true";
        if (!isApproved) {
          setApprovalForAll(nftAddress, operatorAddress);
        } else {
        }
        console.log(`String(result) === "true" ${isApproved}`);
        setIsApprovedForAll(isApproved);
        console.log(`isApprovedForAll result: ${JSON.stringify(result)}`);
      },
    });
    return isApproved;
  };

  async function getERC20MintAddressForNFT(NFTAddress: string, callback: (mintToken: any) => void) {
    const ops = {
      contractAddress: NFTAddress,
      functionName: "getERC20MintToken",
      abi: ERC721Abi.abi,
      params: {},
    };

    let ERC20TokenAdress;
    readWeb3State({
      params: {
        ...ops,
      },

      onSuccess: (mintToken: any) => {
        callback(mintToken)
        ERC20TokenAdress = mintToken;
      },
      onError: (error: any) => {
        console.error(`getERC20MintToken  error ${JSON.stringify(error)}`);
      },
    });

    return ERC20TokenAdress;
  }

  async function hasEnoughAllowance(
    contractTokenAddressToSpend: string,
    spender: string,
    price: string
  ) {
    if (
      contractTokenAddressToSpend ===
      "0x0000000000000000000000000000000000000000"
    ) {
      return true;
    }

    let gotAllowance = 0;

    const ops = {
      contractAddress: contractTokenAddressToSpend,
      functionName: "allowance",
      abi: ERC20Schema.abi,
      params: {
        _owner: account,
        _spender: spender,
      },
    };

    readWeb3State({
      params: {
        ...ops,
      },
      onSuccess: (allowance: any) => {
        console.log("allowance success is enough " + allowance);
        gotAllowance = Number(allowance);
      },
      onError: (error: any) => {
        console.error("allowance error" + error);
      },
    });

    return gotAllowance >= Number(price);
  }

  const mint = (NFTAddress: string, mintPrice: string) => {
    const buyCallback = async (mintToken: any) => {
        buyAndMint(NFTAddress, String(mintPrice),mintToken);

    }
    getERC20MintAddressForNFT(NFTAddress,buyCallback)

  };

  async function buyAndMint(
    NFTAddress: string,
    mintPrice: string,
    ERC20TokenAdress: string
  ) {
    setIsMinting(true);

    // const ERC20TokenAdress = await getERC20MintAddressForNFT(NFTAddress);

    const executeWhenAllowanceIsReady = async function () {
      const options = {
        contractAddress: NFTAddress,
        functionName: "mint",
        abi: ERC721Abi.abi,
        params: {
          to: account,
        },
        msgValue: "0",
      };

      if (ERC20TokenAdress === "0x0000000000000000000000000000000000000000") {
        options.msgValue = mintPrice;
      }

      await executeWeb3CallAndConfirm(options);
      setIsMinting(false);
    };

    const canBuyNFT = await hasEnoughAllowance(
      ERC20TokenAdress,
      NFTAddress,
      mintPrice
    );
    console.log(`canBuyNFT ${canBuyNFT}`)
    if (canBuyNFT) {
      executeWhenAllowanceIsReady();
    } else {
      setIsApproving(true);
      console.log(`setIsApproving(true); ${ERC20TokenAdress}`)

      const options = {
        contractAddress: ERC20TokenAdress,
        functionName: "approve",
        abi: ERC20Schema.abi,
        params: {
          _value: String(MAX_APPROVE_AMOUNT),
          _spender: NFTAddress,
        },
      };

      await executeWeb3CallAndConfirm(options);
      setIsApproving(false);
      await executeWhenAllowanceIsReady();
    }
  }

  return {
    checkIsApprovedForAll,
    isApprovedForAll,
    listNFTs,
    NFTOwnershipLoaded,
    depositFirstOwnedNFT,
    isMinting,
    isApproving,
    mint,
    getERC20MintAddressForNFT,
    ownedNFTsIds,
    NFTOwnershipLoading
  };
};

export { useNFTUtils };
