import { Marketplace } from './../types/index';
import { useState, useEffect } from 'react';
import { findMarketplaceById } from "../lib/fauna";
import { getMarketplaceIdFromLocation } from "../helpers/URLUtils"
export const useMarketplaceData = () => {

    const [marketplaceData, setMarketplaceData] = useState<Marketplace>()

    useEffect(() => {

const fetchData = async() => {
    // console.log("getting useMarketplaceData")
    const marketplaceId = getMarketplaceIdFromLocation()//window.location.pathname.split('/')[1];

    // console.log("marketplaceId " + marketplaceId)

    const marketplace = await findMarketplaceById(marketplaceId);
    setMarketplaceData(marketplace)
}

fetchData()

    },[])
    return {marketplaceData}
}