import { useParams } from "react-router-dom";
import { useMoralis, useChain } from "react-moralis";
import { VaultProfile } from "types";
import { useEffect, useState } from "react";
import { Spin } from "antd";

const ROOT_API =
  process.env.REACT_APP_ROOT_BLOCKONE_API || "https://api.blockonelabs.com/api";

const VaultProfileView = () => {
  const { vaultId } = useParams();
  const { user } = useMoralis();
  const {chainId,account } = useChain();
  const [vault, setVault] = useState<VaultProfile>();


  const [message, setMessage] = useState<string>();



  useEffect(() => {
    const getVault = async () => {
    //   const vault = await findVaultProfileByOwnerAndVaultId(account!, vaultId!);
    //   setVault(vault);
    //   setMessage(vault.description);

      const url = `${ROOT_API}/vaults/profile`;

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        description: message,
        vaultId,
        user: user,
        chainId,
        readOnly: true
      }),
    }).then(async function (res) {
        const profile: VaultProfile = await res.json()
        setVault(profile)
        // console.log(`profile.description: ${res.j}`)
        setMessage(profile.description || "")
        
      console.log(`callback ${JSON.stringify(res)}`);
    });
    };
    if (!vault && user && chainId) {
      getVault();
    }
  }, [account, chainId, message, user, vault, vaultId]);

  const submitProfile = () => {
    const url = `${ROOT_API}/vaults/profile`;

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        description: message,
        vaultId,
        user: user,
        chainId
      }),
    }).then(function (res) {
      console.log(` callback ${JSON.stringify(res)}`);
    });
  };
  
  if(!vault) {
    return <Spin></Spin>
  }

  return (
    <div>
      <h1>Vault Profile {vaultId}</h1>

      <textarea 
      onChange={(e) => setMessage(e.target.value)}
      value={message}
      className="border border-gray-400 rounded-lg p-2 h-36 w-1/2"></textarea>

      <div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => submitProfile()}
        >
          create profile
        </button>
      </div>
    </div>
  );
};

export { VaultProfileView };
