import { MintButton, MintButtonProps } from "./Web3Button";
import { useParams } from "react-router-dom";

import { findNFTCollectionById } from "../lib/fauna";
import { useEffect, useState } from "react";
import htmr from "htmr";
import { Spin } from "antd";
import {TokenDetails, TokenDetailsProps} from "./TokenDetails";

import { useGetMintPriceForNFT } from "hooks/useGetMintPriceForNFT";
import { useNFTUtils } from "hooks/useNFTUtils";

function NFTDetails() {
  const { nftCollectionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [NFTCollection, setNFTCollection] = useState<any>();
  const { mint, isMinting, isApproving } = useNFTUtils();

  const { mintPrice } = useGetMintPriceForNFT(NFTCollection?.contractAddress);

  useEffect(() => {
    const loadNFTFromURL = async () => {
      if (NFTCollection) {
        setLoading(false);
        return;
      }

      const res = await findNFTCollectionById(nftCollectionId || "");
      if (res.contractAddress) {
        setNFTCollection(res);
      }
      setLoading(false);
    };
    loadNFTFromURL();
  }, [NFTCollection, nftCollectionId]);

  if (loading || !NFTCollection) {
    return <Spin/>;
  }

  const renderDescription = () => {
    const richDescription = htmr(
      NFTCollection.description.replaceAll(', "', ",'").replaceAll('",', "',")
    );
    return <div>{richDescription}</div>;
  };

  const getMintPrice = (address: string) => {
    if (mintPrice) {
      return mintPrice;
    }
    return 0;
  }
  console.log(`NFTCollection ${JSON.stringify(NFTCollection)}`)
  const mintButtonProps: MintButtonProps = {
    contractAddress: NFTCollection.contractAddress,
    mintTokenName: NFTCollection.mintTokenName,
    mintDecimals: NFTCollection.mintDecimals,
    mintMethod: (address: string, price: string) => {
      console.log(`mintMethod`)
      mint(address, price)
    },
    getMintPrice: getMintPrice,
    isMinting,
    isApproving
  }
  const renderMintButton = () => {
    return <MintButton {...mintButtonProps}></MintButton>;
  };

  const detailProps: TokenDetailsProps = {
    banner: NFTCollection.banner,
    name: NFTCollection.name,
    description: renderDescription,
    imageURL: NFTCollection.collectionImage,
    renderActionButton: renderMintButton,
  }

  return <TokenDetails {...detailProps}></TokenDetails>;

}

export default NFTDetails;
