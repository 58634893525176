import { useMoralis } from "react-moralis";
import { useEffect, useState } from "react";
import { Spin } from "antd";

export type MintButtonProps = {
  contractAddress: string;
  mintDecimals:number;
  mintTokenName: string;
  mintMethod: (contractAddress: string, price: string) => void;
  getMintPrice: (contractAddress: string) => number | undefined;
  isApproving: boolean;
  isMinting: boolean;


}
function MintButton(inputValue: MintButtonProps ) {
  // console.log("MintButton22 render " + JSON.stringify(inputValue));

  const buttonClasses = `
    bg-blue-500 hover:bg-blue-700 text-white font-bold px-16 rounded
    w-full md:w-80 h-10
    `;
  const disabledButtonClasses = `
    bg-gray-500 text-white font-bold px-16 rounded
    w-full md:w-80 h-10
    `;

  // const { isApproving, isMinting } = useNFTUtils();
  const mintPrice = inputValue.getMintPrice(inputValue.contractAddress);
  // useGetMintPriceForNFT(inputValue.contractAddress);

  const [mintButtonText, setMintButtonText] = useState("Loading price");

  const {
    authenticate,
    isAuthenticated,
    enableWeb3,
    isWeb3Enabled,
    isWeb3EnableLoading,
  } = useMoralis();

  useEffect(() => {
    // console.log("setting mint price text isMinting " + isMinting);
    if (inputValue.isMinting ) {
      setMintButtonText("Minting NFT");
    }
    if (mintPrice ===0){
        setMintButtonText("Free")
    }
    const mintDecimals = inputValue.mintDecimals > 0 ? 10 **inputValue.mintDecimals : 1;
    if (mintPrice) {
      setMintButtonText(
        ` ${mintPrice / mintDecimals} ${
          inputValue.mintTokenName
        }`
      );
    }
  }, [
    inputValue.isMinting,
    setMintButtonText,
    inputValue.mintDecimals,
    inputValue.mintTokenName,
    mintPrice,
  ]);

  if (!isAuthenticated || !isWeb3Enabled || isWeb3EnableLoading) {
    console.log("!isAuthenticated || !isWeb3Enabled || isWeb3EnableLoading");
    return (
      <button
        className={buttonClasses}
        onClick={async () => {
          await authenticate({ signingMessage: "Authentication" });
          enableWeb3();
        }}
      >
        connect your wallet to mint item
      </button>
    );
  }

  return (
    <div>
      <button
        className={inputValue.isMinting ? disabledButtonClasses : buttonClasses}
        disabled={inputValue.isMinting}
        onClick={() => {
          //mint(inputValue.contractAddress, String(mintPrice));
          inputValue.mintMethod(inputValue.contractAddress, String(mintPrice))
          
        }}
      >
        {inputValue.isApproving
          ? `Approving ${inputValue.mintTokenName} `
          : inputValue.isMinting
          ? "Minting "
          : "BUY"}

        {(inputValue.isApproving || inputValue.isMinting) && <Spin spinning={true} className="" />}
      </button>

      <div className="flex justify-center w-full md:w-80 mt-3"></div>
      <div className="text-lg text-gray-500 font-medium my-5">
        Mint price: {mintButtonText}
      </div>
    </div>
  );
}

export { MintButton };
