import { Vault } from "types";
import { TokenDetails, TokenDetailsProps } from "./TokenDetails";
import { useParams } from "react-router-dom";
import { findVaultByID } from "../lib/fauna";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { useNFTUtils } from "hooks/useNFTUtils";
import { MintButton, MintButtonProps } from "./Web3Button";
import { getMarketplaceIdFromLocation } from "helpers/URLUtils";

import {
  NavLink,
} from "react-router-dom";

const VaultDetails = () => {
  const { vaultId } = useParams();
  const marketplaceId = getMarketplaceIdFromLocation();

  

  const [vault, setVault] = useState<Vault>();
  const {
    depositFirstOwnedNFT,
    isApproving,
    isMinting,
    NFTOwnershipLoaded,
    NFTOwnershipLoading,
    listNFTs,
    ownedNFTsIds
  } = useNFTUtils();

  useEffect(() => {
    
    if(!NFTOwnershipLoaded && !NFTOwnershipLoading && vault ) {
      listNFTs(vault.contractAddress)
    }

  },[NFTOwnershipLoaded, NFTOwnershipLoading, listNFTs, vault])

  useEffect(() => {
    const getVault = async () => {
      const vault = await findVaultByID(vaultId!);
      setVault(vault);
    };
    if (!vault) {
      getVault();
    }
  }, [vault, vaultId]);
  if (!vault || !ownedNFTsIds) {
    return <Spin></Spin>;
  }

  const renderActionButton = () => {

    if(ownedNFTsIds && ownedNFTsIds.length > 0) {
      return (
        <div>
          <div>
           {ownedNFTsIds.length} NFTs owned
          </div>
          <div>
          <NavLink to={`/${marketplaceId}/vault/profile/${vault._id}`}>
          go to profile
          </NavLink>
          </div>
          <div>
            
          </div>
         
          
        </div>
      )
    }

    const getMintPrice = (address: string) => {
      return 1;
    }

    const vaultContract: string = vault.contractAddress!;
            const depositToken = vault.depositToken?.contractAddress!;
            const props : MintButtonProps = {
                contractAddress: vaultContract,
                mintDecimals: 0,
                mintTokenName: "",
                mintMethod: () => depositFirstOwnedNFT(vaultContract, depositToken),
                getMintPrice: getMintPrice,
                isApproving,
                isMinting
            }
    return (
      <div>
        {/* MintButton */}

        <MintButton {...props}>

        </MintButton>
        <button
          onClick={async () => {
            const vaultContract: string = vault.contractAddress!;
            const depositToken = vault.depositToken?.contractAddress!;
            // const isDepositTokenApproved =  await checkIsApprovedForAll(depositToken!, account, vaultContract!);
            depositFirstOwnedNFT(vaultContract, depositToken);
            // console.log(`isDepositTokenApproved ${isDepositTokenApproved}`)
          }}
        >
          click me
        </button>
      </div>
    );
  };

  const detailProps: TokenDetailsProps = {
    banner: "",
    name: vault.name!,
    description: () => <div>{vault.description}</div>,
    imageURL: vault.image!,
    renderActionButton: renderActionButton,
  };

  return <TokenDetails {...detailProps}></TokenDetails>;
};
export { VaultDetails };
