import { useEffect, useState, useContext } from "react";
import { useIPFS } from "./useIPFS";
import { useChain } from "react-moralis";
import { MarketplaceContext } from "helpers/marketplaceContext";
import { useMoralis } from "react-moralis";

export const useNFTBalance = (options) => {
  const { marketplaceDetails } = useContext(MarketplaceContext);
  const { Moralis,authError, authenticate, isAuthenticated, isAuthenticating, enableWeb3 } = useMoralis();

  const { chainId } = useChain();

  const { resolveLink } = useIPFS();
  const [NFTBalance, setNFTBalance] = useState([]);

  const [fetchSuccess, setFetchSuccess] = useState(true);

  const [loading, setLoading] = useState(true);

  const [loadedData, setLoadedData] = useState(false);

  const [NFTData, setData] = useState([]);

  console.log("chainId " + chainId);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log("isAuthenticated " + isAuthenticated);

      console.log("getting nfts");
      

      const NFTAddressesToFetch =
        marketplaceDetails?.marketplaceNFTCollections?.data?.map(
          (x) => x?.NFTCollection?.contractAddress
        );

      const urlSearchParams = new URLSearchParams(window.location.search);
      const asAddress = urlSearchParams.get("asAddress");

      const ALLoptions = { chain: chainId };
      if (asAddress) {
        ALLoptions["address"] = asAddress;
      }
      const allNFTs = await Moralis.Web3API.account.getNFTs(ALLoptions);
      console.log("allNFTs " + JSON.stringify(allNFTs));
      const allNFTsResults = allNFTs.result || [];
      const marketPlaceOwnedNFTs = allNFTsResults.filter((x) => {
        return NFTAddressesToFetch?.includes(x?.token_address);
      });

      console.log(`NFTAddressesToFetch ${NFTAddressesToFetch}`);
      console.log(`allNFTsResults ${JSON.stringify(allNFTsResults)}`);
      console.log(
        `marketPlaceOwnedNFTs ${JSON.stringify(marketPlaceOwnedNFTs)}`
      );

      setData([...new Set([...marketPlaceOwnedNFTs])]);

      setLoadedData(true);
    };


    console.log(`authError ${authError}`)
    if (!chainId) {
      console.log("no chain id yet");
    }

    // we don't pop up metamask as soon as page loads
    // if (!isAuthenticated && !isAuthenticating && !authError) {
    //   authenticate({ signingMessage: "Authentication" });
    // }

    if (chainId !== null && !loadedData && isAuthenticated) {
      console.log(`chainId is not null ${chainId}`);
      fetchData();
    }
  }, [
    chainId,
    loadedData,
    authError,
    authenticate,
    isAuthenticating,
    isAuthenticated,
    loading,
    Moralis.Web3API.account,
    marketplaceDetails?.marketplaceNFTCollections?.data,
    enableWeb3,
  ]);

  useEffect(() => {
    async function fetchData() {
      console.log("data2 " + JSON.stringify(NFTData));
      if (NFTData.length > 0) {
        const NFTs = NFTData;
        console.log();
        // console.log("collectionNFTs "  + JSON.stringify(NFTs))

        setFetchSuccess(true);
        const promises = NFTs.map(async (NFT) => {
          console.log("collectionNFT " + JSON.stringify(NFT));
          // if (NFT?.metadata) {
          //   NFT.metadata = JSON.parse(NFT.metadata);
          //   console.log("has metadata")
          //   NFT.image = resolveLink(NFT.metadata?.image);
          // } else
          let tokenURI = NFT?.token_uri;
          const tokenAddress = NFT?.token_address;
          const tokenId = NFT?.token_id;
          if (!tokenURI && tokenAddress && tokenId) {
            const NFTFound =
              marketplaceDetails?.marketplaceNFTCollections?.data?.filter(
                (x) => x?.NFTCollection?.contractAddress === tokenAddress
              );
            console.log(
              `NFTFound?.baseTokenURI ${NFTFound[0]?.NFTCollection.baseTokenURI}`
            );
            if (
              NFTFound.length > 0 &&
              NFTFound[0]?.NFTCollection.baseTokenURI
            ) {
              tokenURI = `${NFTFound[0]?.NFTCollection.baseTokenURI}${tokenId}.json`;
              console.log(`tokenURI updated ${tokenURI}`);
            }

            console.log(`NFTFound ${JSON.stringify(NFTFound)}`);
          }
          console.log(`tokenURI ${tokenURI}`);
          if (tokenURI) {
            console.log("has token URI");
            try {
              await fetch(tokenURI)
                .then((response) => response.json())
                .then((data) => {
                  console.log("NFTData " + NFT.image + " to " + NFTData.image);
                  NFT.image = resolveLink(data.image);
                });
            } catch (error) {
              console.warn(`error while fetching NFT metadata ${error}`);
              setFetchSuccess(false);

              /*          !!Temporary work around to avoid CORS issues when retrieving NFT images!!
        Create a proxy server as per https://dev.to/terieyenike/how-to-create-a-proxy-server-on-heroku-5b5c
        Replace <your url here> with your proxy server_url below
        Remove comments :)

          try {
            await fetch(`<your url here>/${NFT.token_uri}`)
            .then(response => response.json())
            .then(data => {
              NFT.image = resolveLink(data.image);
            });
          } catch (error) {
            setFetchSuccess(false);
          }

*/
            }
            console.log("finished loading");
            setLoading(false);
          }
        });
        await Promise.all(promises);
        setNFTBalance(NFTs);
      } else {
        setLoading(false);
      }
    }
    if (loadedData) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NFTData, loadedData]);

  return { NFTBalance, fetchSuccess, loading };
};
