import { useContext } from "react";

import { MarketplaceContext } from "helpers/marketplaceContext";
import { MarketplaceVault, Maybe } from "types/index";
import {
  Collection,
  CollectionList,
  CollectionListProps,
} from "./CollectionList";
const WorkshopComponent = () => {
  const { marketplaceDetails } = useContext(MarketplaceContext);

  const vaults: Collection[] = marketplaceDetails.vaults.data.map(
    (marketplaceVault: Maybe<MarketplaceVault>) => {
      const vault = marketplaceVault?.vault!;

      const collection: Collection = {
        _id: vault._id,
        name: vault.name,
        collectionImage: vault.image!,
      };
      return collection;
    }
  );

  const listProps: CollectionListProps = {
    collections: vaults,
    viewPath: "vault",
  };

  return (
    <div>
      <CollectionList {...listProps}></CollectionList>
    </div>
  );
};

export { WorkshopComponent };
