import htmr from "htmr";
import { useNavigate } from "react-router-dom";


export type Collection = {
    _id: string;
    banner?: string;
    collectionImage: string;
    name: string;
    description?: string;
}
export type CollectionListProps = {
    collections: Collection[];
    viewPath: string; //NFTDetails
}

const CollectionList = (props:CollectionListProps ) => {
    const navigate = useNavigate();

    return (
        <div>
        <div className="xl:grid-cols-3 lg:grid-cols-2 my-8 grid gap-2 grid-cols-1 mx-8">
          {props.collections?.map((nft) => {
            // const nft = NFTMarketplaceCollection.NFTCollection;
            return (
              <div>
                <div
                  className="w-80 md:w-96 cursor-pointer
                   shadow hover:shadow-2xl drop-shadow-2xl rounded
                   mb-3
                   "
                  onClick={() => {
                    const navigateURL = `./../${props.viewPath}/${nft._id}`;
                    navigate(navigateURL);
                  }}
                >
                  <div className="">
                    <img
                      className="object-scale-down rounded"
                      src={nft?.banner || nft?.collectionImage || "error"}
                      alt="nft banner"
                    />
                  </div>

                  <div className="flex items-center justify-center">
                    <img
                      className="h-24 w-24 -mt-8"
                      src={nft?.collectionImage || "error"}
                      alt="nft"
                    />
                  </div>

                  <div className="flex items-center justify-center my-5 text-2xl	">
                    {nft.name}
                  </div>
                  <div className="flex pb-4 px-5 my-5 text-base text-center  text-gray-500	">
                    {htmr(
                      (nft.description  || "")
                        .replaceAll(', "', ",'")
                        .replaceAll('",', "',")
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
}

export { CollectionList };