import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import MoralisDappContext from "./context";

function MoralisDappProvider({ children }) {
  const { web3, Moralis, user } = useMoralis();
  const [walletAddress, setWalletAddress] = useState();
  const [chainId, setChainId] = useState();       
  const [contractABI, setContractABI] = useState(`[
    {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "fee",
              "type": "uint256"
          },
          {
              "internalType": "address",
              "name": "feeRecipient",
              "type": "address"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": false,
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          }
      ],
      "name": "ListingCancelled",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "bool",
              "name": "isErc721",
              "type": "bool"
          },
          {
              "indexed": true,
              "internalType": "address",
              "name": "nftAddress",
              "type": "address"
          },
          {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "address",
              "name": "erc20Address",
              "type": "address"
          }
      ],
      "name": "ListingCreated",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "buyer",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          }
      ],
      "name": "ListingSold",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
          },
          {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
          }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
  },
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          },
          {
              "internalType": "address",
              "name": "erc20Address",
              "type": "address"
          }
      ],
      "name": "buyAssetFromListing",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          },
          {
              "internalType": "address",
              "name": "erc20Address",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "buyer",
              "type": "address"
          }
      ],
      "name": "buyAssetFromListingForExternalBuyer",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          }
      ],
      "name": "cancelListing",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          },
          {
              "internalType": "bool",
              "name": "isErc721",
              "type": "bool"
          },
          {
              "internalType": "address",
              "name": "nftAddress",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
          },
          {
              "internalType": "address",
              "name": "seller",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
          },
          {
              "internalType": "address",
              "name": "erc20Address",
              "type": "address"
          }
      ],
      "name": "createListing",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "listingId",
              "type": "string"
          }
      ],
      "name": "getListing",
      "outputs": [
          {
              "components": [
                  {
                      "internalType": "string",
                      "name": "listingId",
                      "type": "string"
                  },
                  {
                      "internalType": "bool",
                      "name": "isErc721",
                      "type": "bool"
                  },
                  {
                      "internalType": "enum MarketplaceListing.State",
                      "name": "state",
                      "type": "uint8"
                  },
                  {
                      "internalType": "address",
                      "name": "nftAddress",
                      "type": "address"
                  },
                  {
                      "internalType": "address",
                      "name": "seller",
                      "type": "address"
                  },
                  {
                      "internalType": "address",
                      "name": "erc20Address",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "amount",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "price",
                      "type": "uint256"
                  },
                  {
                      "internalType": "address",
                      "name": "buyer",
                      "type": "address"
                  }
              ],
              "internalType": "struct MarketplaceListing.Listing",
              "name": "",
              "type": "tuple"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getMarketplaceFee",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getMarketplaceFeeRecipient",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getOpenListings",
      "outputs": [
          {
              "internalType": "string[]",
              "name": "",
              "type": "string[]"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "uint256[]",
              "name": "",
              "type": "uint256[]"
          },
          {
              "internalType": "uint256[]",
              "name": "",
              "type": "uint256[]"
          },
          {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
          }
      ],
      "name": "onERC1155BatchReceived",
      "outputs": [
          {
              "internalType": "bytes4",
              "name": "",
              "type": "bytes4"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          },
          {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
          }
      ],
      "name": "onERC1155Received",
      "outputs": [
          {
              "internalType": "bytes4",
              "name": "",
              "type": "bytes4"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          },
          {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
          }
      ],
      "name": "onERC721Received",
      "outputs": [
          {
              "internalType": "bytes4",
              "name": "",
              "type": "bytes4"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "owner",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "fee",
              "type": "uint256"
          }
      ],
      "name": "setMarketplaceFee",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "recipient",
              "type": "address"
          }
      ],
      "name": "setMarketplaceFeeRecipient",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
          }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "stateMutability": "payable",
      "type": "receive"
  }
  ]`); //Smart Contract ABI here
  const [marketAddress, setMarketAddress] = useState("0x86b644A491eba79f79045a22c91b534d3AA00916"); //Smart Contract Address Here


  useEffect(() => {
    Moralis.onChainChanged(function (chain) {
      setChainId(chain);
    });

    Moralis.onAccountChanged(function (address) {
      setWalletAddress(address[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setChainId(web3?.givenProvider?.chainId));
  useEffect(
    () => setWalletAddress(web3?.givenProvider?.selectedAddress || user?.get("ethAddress")),
    [web3, user]
  );

  return (
    <MoralisDappContext.Provider value={{ walletAddress, chainId, marketAddress, setMarketAddress, contractABI, setContractABI }}>
      {children}
    </MoralisDappContext.Provider>
  );
}

function useMoralisDapp() {
  const context = React.useContext(MoralisDappContext);
  if (context === undefined) {
    throw new Error("useMoralisDapp must be used within a MoralisDappProvider");
  }
  return context;
}

export { MoralisDappProvider, useMoralisDapp };
