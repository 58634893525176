export type TokenDetailsProps = {
    banner: string;
    name: string;
    description: () => JSX.Element;
    imageURL: string;
    renderActionButton: () => JSX.Element;

}
const TokenDetails =  (props: TokenDetailsProps) => {

    return (
        <>
        <div className="">
          <div
            className="bg-cover bg-center  h-auto text-white py-24 px-10 object-fill"
            style={{ backgroundImage: `url(${props.banner || ""})` }}
          >
            <div className="md:w-1/2"></div>
          </div>
        </div>
        <div className="mt-14 lg:mx-28">
          <div className=" grid grid-cols-1 md:grid-cols-2 px-4">
            <div className="col-span-1 lg:order-1 order-2">
              <div>
                <p className="text-3xl font-extrabold mb-8">
                  {props.name}
                </p>
              </div>
              {props.renderActionButton()}
              {/* {renderMintButton()} */}
              <div>
                <div className="mb-8  text-xl font-bold">Description</div>
                <div className="mb-10">{props.description}</div>
              </div>
            </div>
  
            <div className="-mt-10 col-span-1	w-96 order-1">
              <img
                alt="Collection"
                className="rounded-lg w-screen md:w-96"
                src={props.imageURL}
              ></img>
            </div>
          </div>
        </div>
      </>
    )

}

export { TokenDetails };